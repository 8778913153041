const MAX_ANIMATION_ELEMENTS = 12;
const loginForm = document.getElementById('js-form-login');
const loginButton = document.getElementById('js-button-login');

// When the login form is submitted:
loginForm.addEventListener('submit', () => {
  // Disable the login button
  loginButton.setAttribute('disabled', 'disabled');

  // Create the animation wrapper
  const animation = document.createElement('div');

  // Add the animation elements
  for (let index = 0; index < MAX_ANIMATION_ELEMENTS; index++) {
    const animationElement = document.createElement('div');
    animation.appendChild(animationElement);
  }

  // Add the class style to the animation wrapper
  animation.setAttribute('class', 'action-spinner');

  // Insert the animation into the login button
  loginButton.appendChild(animation);
});
